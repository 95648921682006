<template>
  <div class="teaching">
    <img class="ssimg" src="../../assets/img/zxdz99+.png" alt="" />
    <img class="ssimg" src="../../assets/img/66+.png" alt="" />
    <img
      class="ssimg ddssa"
      @click.stop="jianzhi(1)"
      src="../../assets/img/zxz857.png"
      alt=""
    />
    <div class="disflex1" v-if="dioindex > -1" @click="jianzhi(-1)">
      <div class="ssp1">
        <div class="copyphone1" @click.stop="copys">复制号码</div>
        <img
          style="width: 100%; display: block"
          v-if="dioindex == 1"
          src="../../assets/img/zxzqss1xcx.png"
          alt=""
        />

        <img
          style="width: 100%; display: block"
          @click="jianzhi(-1)"
          class="closes1"
          src="https://img.zhixiaozi.com/mrcba/images/closell.png"
          alt=""
        />
      </div>
    </div>
    <page-footer />
    <div class="disflex1" v-if="copys1 == 1">
      <div class="dd2">
        <div class="dd21">已复制</div>
        <div class="dd22" @click.stop="closecopys1">关闭</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dioindex: -1,
      copys1: -1,
    };
  },
  components: {
    "page-footer": () => import("../../components/footer.vue"),
  },
  methods: {
    showdio() {
      console.log(1);
    },
    jianzhi(i) {
      this.dioindex = i;
    },
    copys() {
      //拨打电话
      //window.location.href = "tel://18028289102";
      let url = "18028289102";
      let oInput = document.createElement("input");
      oInput.value = url;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象;
      //console.log(oInput.value);
      document.execCommand("Copy"); // 执行浏览器复制命令
      oInput.remove();
      // this.$dialog.alert({
      //   title: "", //加上标题
      //   message: "已复制", //改变弹出框的内容
      //   //showCancelButton: true, //展示取水按钮
      // });
      this.dioindex = -1;
      this.copys1 = 1;
    },
    closecopys1() {
      this.copys1 = -1;
    },
  },
};
</script>

<style>
.teaching {
  position: relative;
  width: 100%;
}
.ssimg {
  display: block;
  width: 100%;
}
.ddssa {
  position: absolute;
  bottom: 26%;
  display: block;
  width: 80%;
  left: 10%;
}
.dd2 {
  background: #ffffff;
  border-radius: 12px;
  position: absolute;
  top: 40%;
  left: 20%;
  width: 60%;
}
.dd21 {
  padding: 1em;
  border-bottom: 1px solid #b6b5b5;
  text-align: center;
}
.dd22 {
  padding: 0.5em;
  text-align: center;
}
.closes1 {
  width: 30px !important;
  height: 30px !important;
  margin-top: 50px !important;
  position: absolute;
  bottom: -26%;
  left: 41%;
}
.copyphone1 {
  background: #ffffff;
  opacity: 1;
  font-size: 1em;
  font-family: PingFang SC, PingFang SC-Regular;
  font-weight: 400;
  text-align: center;
  color: #08c2cb;
  position: absolute;
  top: 89%;
  width: 100%;
  padding: 0.8em 0em;
  border-radius: 0px 0px 10px 10px;
}
.disflex1 {
  z-index: 99;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 999;
}
.ssp1 {
  height: auto;
  width: 82%;
  position: absolute;
  top: 14%;
  left: 10%;
  text-align: center;
}
</style>
